@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap);* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-family: Quicksand,sans-serif;
  margin: 0;
  padding: 0
}

body {
  background-color: #f5f5f5;
  padding-top: 70px
}

.container {
  gap: 15px;
  margin: 0 auto;
  padding: 0;
  width: 90%
}

.container,.month {
  align-items: stretch;
  display: flex
}

.month {
  background-color: #fff;
  border-radius: 3px;
  justify-content: space-between;
  padding: 10;
  width: 100%
}

.results {
  border: none;
  flex: 1 1;
  font-size: 14px;
  padding: 10px
}

.results::-webkit-scrollbar {
  display: none
}

.drop {
  align-items: center;
  border: 1px dashed #e5322d;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 200px
}

.active,.drop:hover {
  border: 1px dashed #3bffeb
}

.danger {
  border: 1px dashed tomato
}

.plus {
  width: 100%
}

.btn {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50px
}

.btn,button {
  cursor: pointer
}

button {
  padding: 5px 10px
}

.run {
  border: none;
  bottom: 0;
  outline: none;
  position: absolute;
  right: 0;
  z-index: 8
}

.list_container {
  display: flex;
  gap: 10px;
  padding: 20px;
  width: 100%
}

.item {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  transition: all .3s ease-in-out
}

.item:hover {
  box-shadow: 0 4px 16px #11111a0d,0 8px 32px #11111a0d;
  transform: scale(1.05)
}

.list {
  gap: 10px;
  max-width: 30%
}

.items,.list {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.items {
  gap: 2px
}

.view {
  flex: 1 1;
  height: 100vh;
  position: relative
}

.pdf {
  position: fixed
}

.pdf,.pdfview {
  background-color: #fff;
  height: 100%;
  width: 100%
}

.left {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-top: 20px
}

.right {
  border: 1px solid #fff;
  flex: 1 1;
  min-height: 100vh
}

.month {
  position: relative
}

.rm {
  background-color: #f5f5f5;
  cursor: pointer;
  left: 169px;
  padding: 5px;
  position: absolute;
  top: 1px;
  width: 30px;
  z-index: 8
}

.rm:hover svg {
  color: tomato
}

.messages {
  background: #fff;
  border-left: 3px solid #40e0d0;
  border-radius: 5px;
  box-shadow: 0 7px 29px 0 #64646f33;
  cursor: pointer;
  padding: 24px;
  right: 5%;
  top: 18px;
  width: 320px
}

.messages,.modal {
  position: fixed;
  z-index: 999
}

.modal {
  background-color: rgba(0,0,0,.281);
  height: 100%;
  justify-content: center;
  padding-top: 150px;
  width: 100%
}

.modal,.modal-body {
  align-items: flex-start;
  display: flex
}

.modal-body {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 29px 0 #64646f33;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  padding: 20px;
  width: 500px
}

.modal-body input,.modal-body label {
  width: 100%
}

.modal-body input {
  font-size: 16px;
  padding: 10px
}

.modal-body .actions {
  display: flex;
  gap: 20px;
  padding-top: 10px
}

.modal-body button {
  cursor: pointer;
  padding: 10px;
  width: 100px
}

.navbar {
  background-color: #fff;
  box-shadow: 1.95px 1.95px 2.6px #00000026;
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9
}

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%
}

.nav span {
  color: #01a77d;
  font-size: 18px;
  font-weight: 700
}

.nav .links {
  gap: 10px;
  justify-content: space-between
}

.btng,.nav .links {
  align-items: center;
  display: flex
}

.btng {
  background-color: #e5322d;
  border-radius: 50%;
  box-shadow: 0 2px 8px 0 #63636333;
  height: 35px;
  justify-content: center;
  margin-top: -10px;
  transition: all .3s ease-in-out;
  width: 35px
}

svg {
  fill: #e5322d
}

.btng:hover {
  transform: scale(1.1)
}

button {
  background-color: #e5322d;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  outline: none;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: all .3s ease-in-out
}

button:hover {
  box-shadow: 0 2px 8px 0 #63636333;
  transform: scale(1.1)
}

button:disabled {
  background-color: #dcdcdc;
  transform: none;
  transition: none
}

.Toastify>* {
  z-index: 10000
}

::-webkit-scrollbar {
  width: 8px
}

::-webkit-scrollbar-track {
  background: #f1f1f1
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}

.btn-setfolder {
  align-items: center;
  background-color: initial!important;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  transform: none;
  transition: none
}

.btn-setfolder:hover {
  box-shadow: none;
  transform: none
}

.App {
  text-align: center
}

.App-logo {
  height: 40vmin;
  pointer-events: none
}

@media (prefers-reduced-motion:no-preference) {
  .App-logo {
      animation: App-logo-spin 20s linear infinite
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh
}

.App-link {
  color: #61dafb
}

@keyframes App-logo-spin {
  0% {
      transform: rotate(0deg)
  }

  to {
      transform: rotate(1turn)
  }
}

.footer {
  align-items: center;
  border-top: 1px solid #fff;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%
}

.footer p {
  font-size: 12px
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset),env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset),env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset),env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset),env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(90deg,#4cd964,#5ac8fa,#007aff,#34aadc,#5856d6,#ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: 0.2
}

.Toastify__toast-container {
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
  -webkit-transform: translateZ(9999);
  -webkit-transform: translateZ(var(--toastify-z-index));
  width: 320px;
  width: var(--toastify-toast-width);
  z-index: 9999;
  z-index: var(--toastify-z-index)
}

.Toastify__toast-container--top-left {
  left: max(16px,env(safe-area-inset-left));
  left: var(--toastify-toast-left);
  top: max(16px,env(safe-area-inset-top));
  top: var(--toastify-toast-top)
}

.Toastify__toast-container--top-center {
  left: 50%;
  top: max(16px,env(safe-area-inset-top));
  top: var(--toastify-toast-top);
  transform: translateX(-50%)
}

.Toastify__toast-container--top-right {
  right: max(16px,env(safe-area-inset-right));
  right: var(--toastify-toast-right);
  top: max(16px,env(safe-area-inset-top));
  top: var(--toastify-toast-top)
}

.Toastify__toast-container--bottom-left {
  bottom: max(16px,env(safe-area-inset-bottom));
  bottom: var(--toastify-toast-bottom);
  left: max(16px,env(safe-area-inset-left));
  left: var(--toastify-toast-left)
}

.Toastify__toast-container--bottom-center {
  bottom: max(16px,env(safe-area-inset-bottom));
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%)
}

.Toastify__toast-container--bottom-right {
  bottom: max(16px,env(safe-area-inset-bottom));
  bottom: var(--toastify-toast-bottom);
  right: max(16px,env(safe-area-inset-right));
  right: var(--toastify-toast-right)
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
      left:env(safe-area-inset-left);
      margin: 0;
      padding: 0;
      width: 100vw
  }

  .Toastify__toast-container--top-center,.Toastify__toast-container--top-left,.Toastify__toast-container--top-right {
      top: env(safe-area-inset-top);
      transform: translateX(0)
  }

  .Toastify__toast-container--bottom-center,.Toastify__toast-container--bottom-left,.Toastify__toast-container--bottom-right {
      bottom: env(safe-area-inset-bottom);
      transform: translateX(0)
  }

  .Toastify__toast-container--rtl {
      left: auto;
      right: env(safe-area-inset-right)
  }
}

.Toastify__toast {
  --y: 0;
  border-radius: 6px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0 4px 12px #0000001a;
  box-sizing: border-box;
  cursor: default;
  direction: ltr;
  display: flex;
  font-family: sans-serif;
  font-family: var(--toastify-font-family);
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 800px;
  max-height: var(--toastify-toast-max-height);
  min-height: 64px;
  min-height: var(--toastify-toast-min-height);
  overflow: hidden;
  padding: 8px;
  position: relative;
  touch-action: none;
  z-index: 0
}

.Toastify__toast--stacked {
  position: absolute;
  transform: translate3d(0,var(--y),0) scale(var(--s));
  transition: transform .3s;
  width: 100%
}

.Toastify__toast--stacked[data-collapsed] .Toastify__close-button,.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body {
  transition: opacity .1s
}

.Toastify__toast--stacked[data-collapsed=false] {
  overflow: visible
}

.Toastify__toast--stacked[data-collapsed=true]:not(:last-child)>* {
  opacity: 0
}

.Toastify__toast--stacked:after {
  bottom: 100%;
  content: "";
  height: calc(var(--g)*1px);
  left: 0;
  position: absolute;
  right: 0
}

.Toastify__toast--stacked[data-pos=top] {
  top: 0
}

.Toastify__toast--stacked[data-pos=bot] {
  bottom: 0
}

.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
  transform-origin: top
}

.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
  transform-origin: bottom
}

.Toastify__toast--stacked:before {
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  transform: scaleY(3);
  z-index: -1
}

.Toastify__toast--rtl {
  direction: rtl
}

.Toastify__toast--close-on-click {
  cursor: pointer
}

.Toastify__toast-body {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  margin: auto 0;
  padding: 6px
}

.Toastify__toast-body>div:last-child {
  flex: 1 1;
  word-break: break-word
}

.Toastify__toast-icon {
  display: flex;
  flex-shrink: 0;
  margin-inline-end:10px;width: 20px
}

.Toastify--animate {
  animation-duration: .5s;
  animation-fill-mode: both
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
      border-radius:0;
      margin-bottom: 0
  }
}

.Toastify__toast-theme--dark {
  background: #121212;
  background: var(--toastify-color-dark);
  color: #fff;
  color: var(--toastify-text-color-dark)
}

.Toastify__toast-theme--colored.Toastify__toast--default,.Toastify__toast-theme--light {
  background: #fff;
  background: var(--toastify-color-light);
  color: #757575;
  color: var(--toastify-text-color-light)
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #3498db;
  background: var(--toastify-color-info);
  color: #fff;
  color: var(--toastify-text-color-info)
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #07bc0c;
  background: var(--toastify-color-success);
  color: #fff;
  color: var(--toastify-text-color-success)
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #f1c40f;
  background: var(--toastify-color-warning);
  color: #fff;
  color: var(--toastify-text-color-warning)
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #e74c3c;
  background: var(--toastify-color-error);
  color: #fff;
  color: var(--toastify-text-color-error)
}

.Toastify__progress-bar-theme--light {
  background: linear-gradient(90deg,#4cd964,#5ac8fa,#007aff,#34aadc,#5856d6,#ff2d55);
  background: var(--toastify-color-progress-light)
}

.Toastify__progress-bar-theme--dark {
  background: #bb86fc;
  background: var(--toastify-color-progress-dark)
}

.Toastify__progress-bar--info {
  background: #3498db;
  background: var(--toastify-color-progress-info)
}

.Toastify__progress-bar--success {
  background: #07bc0c;
  background: var(--toastify-color-progress-success)
}

.Toastify__progress-bar--warning {
  background: #f1c40f;
  background: var(--toastify-color-progress-warning)
}

.Toastify__progress-bar--error {
  background: #e74c3c;
  background: var(--toastify-color-progress-error)
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error,.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background: #ffffffb3;
  background: var(--toastify-color-transparent)
}

.Toastify__close-button {
  align-self: flex-start;
  background: #0000;
  border: none;
  color: #fff;
  cursor: pointer;
  opacity: .7;
  outline: none;
  padding: 0;
  transition: .3s ease;
  z-index: 1
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3
}

.Toastify__close-button>svg {
  fill: currentColor;
  height: 16px;
  width: 14px
}

.Toastify__close-button:focus,.Toastify__close-button:hover {
  opacity: 1
}

@keyframes Toastify__trackProgress {
  0% {
      transform: scaleX(1)
  }

  to {
      transform: scaleX(0)
  }
}

.Toastify__progress-bar {
  border-bottom-left-radius: 6px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: .7;
  position: absolute;
  transform-origin: left;
  width: 100%;
  z-index: 9999;
  z-index: var(--toastify-z-index)
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards
}

.Toastify__progress-bar--controlled {
  transition: transform .2s
}

.Toastify__progress-bar--rtl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
  left: auto;
  right: 0;
  transform-origin: right
}

.Toastify__progress-bar--wrp {
  border-bottom-left-radius: 6px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  width: 100%
}

.Toastify__progress-bar--wrp[data-hidden=true] {
  opacity: 0
}

.Toastify__progress-bar--bg {
  height: 100%;
  opacity: .2;
  opacity: var(--toastify-color-progress-bgo);
  width: 100%
}

.Toastify__spinner {
  animation: Toastify__spin .65s linear infinite;
  border: 2px solid #e0e0e0;
  border-color: var(--toastify-spinner-color-empty-area);
  border-radius: 100%;
  border-right-color: #616161;
  border-right-color: var(--toastify-spinner-color);
  box-sizing: border-box;
  height: 20px;
  width: 20px
}

@keyframes Toastify__bounceInRight {
  0%,60%,75%,90%,to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      transform: translate3d(3000px,0,0)
  }

  60% {
      opacity: 1;
      transform: translate3d(-25px,0,0)
  }

  75% {
      transform: translate3d(10px,0,0)
  }

  90% {
      transform: translate3d(-5px,0,0)
  }

  to {
      transform: none
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
      opacity: 1;
      transform: translate3d(-20px,var(--y),0)
  }

  to {
      opacity: 0;
      transform: translate3d(2000px,var(--y),0)
  }
}

@keyframes Toastify__bounceInLeft {
  0%,60%,75%,90%,to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      transform: translate3d(-3000px,0,0)
  }

  60% {
      opacity: 1;
      transform: translate3d(25px,0,0)
  }

  75% {
      transform: translate3d(-10px,0,0)
  }

  90% {
      transform: translate3d(5px,0,0)
  }

  to {
      transform: none
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
      opacity: 1;
      transform: translate3d(20px,var(--y),0)
  }

  to {
      opacity: 0;
      transform: translate3d(-2000px,var(--y),0)
  }
}

@keyframes Toastify__bounceInUp {
  0%,60%,75%,90%,to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      transform: translate3d(0,3000px,0)
  }

  60% {
      opacity: 1;
      transform: translate3d(0,-20px,0)
  }

  75% {
      transform: translate3d(0,10px,0)
  }

  90% {
      transform: translate3d(0,-5px,0)
  }

  to {
      transform: translateZ(0)
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
      transform: translate3d(0,calc(var(--y) - 10px),0)
  }

  40%,45% {
      opacity: 1;
      transform: translate3d(0,calc(var(--y) + 20px),0)
  }

  to {
      opacity: 0;
      transform: translate3d(0,-2000px,0)
  }
}

@keyframes Toastify__bounceInDown {
  0%,60%,75%,90%,to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      transform: translate3d(0,-3000px,0)
  }

  60% {
      opacity: 1;
      transform: translate3d(0,25px,0)
  }

  75% {
      transform: translate3d(0,-10px,0)
  }

  90% {
      transform: translate3d(0,5px,0)
  }

  to {
      transform: none
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
      transform: translate3d(0,calc(var(--y) - 10px),0)
  }

  40%,45% {
      opacity: 1;
      transform: translate3d(0,calc(var(--y) + 20px),0)
  }

  to {
      opacity: 0;
      transform: translate3d(0,2000px,0)
  }
}

.Toastify__bounce-enter--bottom-left,.Toastify__bounce-enter--top-left {
  animation-name: Toastify__bounceInLeft
}

.Toastify__bounce-enter--bottom-right,.Toastify__bounce-enter--top-right {
  animation-name: Toastify__bounceInRight
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp
}

.Toastify__bounce-exit--bottom-left,.Toastify__bounce-exit--top-left {
  animation-name: Toastify__bounceOutLeft
}

.Toastify__bounce-exit--bottom-right,.Toastify__bounce-exit--top-right {
  animation-name: Toastify__bounceOutRight
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown
}

@keyframes Toastify__zoomIn {
  0% {
      opacity: 0;
      transform: scale3d(.3,.3,.3)
  }

  50% {
      opacity: 1
  }
}

@keyframes Toastify__zoomOut {
  0% {
      opacity: 1
  }

  50% {
      opacity: 0;
      transform: translate3d(0,var(--y),0) scale3d(.3,.3,.3)
  }

  to {
      opacity: 0
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut
}

@keyframes Toastify__flipIn {
  0% {
      animation-timing-function: ease-in;
      opacity: 0;
      transform: perspective(400px) rotateX(90deg)
  }

  40% {
      animation-timing-function: ease-in;
      transform: perspective(400px) rotateX(-20deg)
  }

  60% {
      opacity: 1;
      transform: perspective(400px) rotateX(10deg)
  }

  80% {
      transform: perspective(400px) rotateX(-5deg)
  }

  to {
      transform: perspective(400px)
  }
}

@keyframes Toastify__flipOut {
  0% {
      transform: translate3d(0,var(--y),0) perspective(400px)
  }

  30% {
      opacity: 1;
      transform: translate3d(0,var(--y),0) perspective(400px) rotateX(-20deg)
  }

  to {
      opacity: 0;
      transform: translate3d(0,var(--y),0) perspective(400px) rotateX(90deg)
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut
}

@keyframes Toastify__slideInRight {
  0% {
      transform: translate3d(110%,0,0);
      visibility: visible
  }

  to {
      transform: translate3d(0,var(--y),0)
  }
}

@keyframes Toastify__slideInLeft {
  0% {
      transform: translate3d(-110%,0,0);
      visibility: visible
  }

  to {
      transform: translate3d(0,var(--y),0)
  }
}

@keyframes Toastify__slideInUp {
  0% {
      transform: translate3d(0,110%,0);
      visibility: visible
  }

  to {
      transform: translate3d(0,var(--y),0)
  }
}

@keyframes Toastify__slideInDown {
  0% {
      transform: translate3d(0,-110%,0);
      visibility: visible
  }

  to {
      transform: translate3d(0,var(--y),0)
  }
}

@keyframes Toastify__slideOutRight {
  0% {
      transform: translate3d(0,var(--y),0)
  }

  to {
      transform: translate3d(110%,var(--y),0);
      visibility: hidden
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
      transform: translate3d(0,var(--y),0)
  }

  to {
      transform: translate3d(-110%,var(--y),0);
      visibility: hidden
  }
}

@keyframes Toastify__slideOutDown {
  0% {
      transform: translate3d(0,var(--y),0)
  }

  to {
      transform: translate3d(0,500px,0);
      visibility: hidden
  }
}

@keyframes Toastify__slideOutUp {
  0% {
      transform: translate3d(0,var(--y),0)
  }

  to {
      transform: translate3d(0,-500px,0);
      visibility: hidden
  }
}

.Toastify__slide-enter--bottom-left,.Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft
}

.Toastify__slide-enter--bottom-right,.Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp
}

.Toastify__slide-exit--bottom-left,.Toastify__slide-exit--top-left {
  animation-duration: .3s;
  animation-name: Toastify__slideOutLeft;
  animation-timing-function: ease-in
}

.Toastify__slide-exit--bottom-right,.Toastify__slide-exit--top-right {
  animation-duration: .3s;
  animation-name: Toastify__slideOutRight;
  animation-timing-function: ease-in
}

.Toastify__slide-exit--top-center {
  animation-duration: .3s;
  animation-name: Toastify__slideOutUp;
  animation-timing-function: ease-in
}

.Toastify__slide-exit--bottom-center {
  animation-duration: .3s;
  animation-name: Toastify__slideOutDown;
  animation-timing-function: ease-in
}

@keyframes Toastify__spin {
  0% {
      transform: rotate(0deg)
  }

  to {
      transform: rotate(1turn)
  }
}

